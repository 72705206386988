<template>
    <div class="mt-4 mb-4">
        <div v-if="!isLoading" class="table-responsive">
            <table class="table border">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Stock</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Returned Kits</td>
                        <td>{{ returnedKitsStock }}</td>
                        <td>{{ updatedAt }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'returned-kits-stock',
  data() {
    return {
      isLoading: true,
      returnedKitsStock: 0,
      updatedAt: 'Error getting stock info',
    };
  },
  methods: {
    async getStock() {
      const res = await this.$store.dispatch('OrderManagement/getReturnedKitsStock');
      if (res == null) {
        console.error('No data returned from API');
        this.isLoading = false;
        return;
      }

      this.returnedKitsStock = res.returned_stock;
      this.updatedAt = moment(res.updated_at).format('YYYY-MM-DD HH:mm');
      this.isLoading = false;
    },
  },
  mounted() {
    this.getStock();
  },
};
</script>
