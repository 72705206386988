<template>
  <div class="p-3">
    <header>
      <h3>Stock values</h3>
    </header>
    <div>
      <b-row>
        <b-col>
          <div class="bs-callout bs-callout-info">
            <p>Below are the current stock values (reserve and returned) of components grouped by product type currently
              on Deposco. <b>Values are updated every hour</b>.</p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="pt-3">
      <b-tabs content-class="mt-6">
        <b-tab title="Spare Parts" active>
          <stock productType="spare_part"></stock>
        </b-tab>
        <b-tab lazy title="Tablets">
          <stock productType="tablet"></stock>
        </b-tab>
        <b-tab lazy title="Motion Trackers">
          <stock productType="motion_tracker"></stock>
        </b-tab>
        <b-tab lazy title="Semi-Kits">
          <stock productType="semi_kit"></stock>
        </b-tab>
        <b-tab lazy title="Returned Kits">
          <returned-kits-stock></returned-kits-stock>
        </b-tab>
        <b-tab lazy title="Production Materials">
          <stock productType="shipping_material"></stock>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import stock from '../../../components/OrderManagement/Stock/list.vue';
import returnedKitsStock from '../../../components/OrderManagement/Stock/ReturnedKits.vue';

export default {
  name: 'stock-list',
  components: {
    stock,
    returnedKitsStock,
  },
};
</script>
<style lang="scss">
@import '../../../styles/OrderManagement.scss';
</style>
