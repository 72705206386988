<template>
  <div class="mt-4 mb-4">
    <b-spinner v-if="loadingPage"></b-spinner>
    <div v-else class="table-responsive">
      <table class="table border">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">SKU</th>
          <th scope="col" v-if="isStockColumnVisible(stockColumns.returned)">Returned</th>
          <th scope="col" v-if="isStockColumnVisible(stockColumns.receiving)">Receiving</th>
          <th scope="col" v-if="isStockColumnVisible(stockColumns.reserved)">Reserve Storage</th>
          <th scope="col" v-if="isStockColumnVisible(stockColumns.discharged)">Discharged/Update</th>
          <th scope="col">Total Stock</th>
          <th scope="col">Updated At</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="component in components" :key="component.sku">
          <td class="col-2">{{ component.name }}</td>
          <td class="col-2">{{ component.sku }}</td>
          <td class="col-1" v-if="isStockColumnVisible(stockColumns.returned)">{{ component.returned }}</td>
          <td class="col-1" v-if="isStockColumnVisible(stockColumns.receiving)">{{ component.receiving }}</td>
          <td class="col-1" v-if="isStockColumnVisible(stockColumns.reserved)">{{ component.reserve_storage }}</td>
          <td class="col-1" v-if="isStockColumnVisible(stockColumns.discharged)">{{ component.discharged }}</td>
          <td class="col-1">{{ component.stock }}</td>
          <td class="col-2">{{ component.updated }}</td>
        </tr>
        </tbody>
      </table>
      <div v-if="showPagination()" class="col-12 d-flex justify-content-center mt-2">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';

const ProductTypeSparePart = 'spare_part';
const ProductTypeTablet = 'tablet';
const ProductTypeMotionTracker = 'motion_tracker';
const ProductTypeSemiKit = 'semi_kit';
const ProductTypeShippingMaterial = 'shipping_material';

const StockLocationReturned = 'returned';
const StockLocationReserved = 'reserved';
const StockLocationReceiving = 'receiving';
const StockLocationDischarged = 'discharged';

const SemiKitStockColumns = [ StockLocationReserved, StockLocationReceiving ];
const TabletStockColumns = [ StockLocationReturned, StockLocationReserved, StockLocationDischarged ];
const MotionTrackerStockColumns = [ StockLocationReturned, StockLocationReserved, StockLocationDischarged ];
const ShippingMaterialsStockColumns = [ StockLocationReturned, StockLocationReserved, StockLocationDischarged ];

export default {
  name: 'stock-list-component',
  data() {
    return {
      loadingPage: false,
      itemsPerPage: 8,
      currentPage: 1,
      total: 0,
      components: [],
      stockColumns: {
        returned: StockLocationReturned,
        reserved: StockLocationReserved,
        receiving: StockLocationReceiving,
        discharged: StockLocationDischarged,
      },
    };
  },
  props: {
    productType: String,
  },
  async created() {
    switch (this.productType) {
      case ProductTypeSparePart:
        await this.getSkus();
        break;
      default:
        await this.loadComponents();
        break;
    }
  },
  methods: {
    async loadComponents() {
      this.loadingPage = true;

      const params = {
        offset: 0,
        limit: 100,
        type: this.$props.productType,
      };

      const res = await this.$store.dispatch('CSM/getComponents', params);
      if (res.data == null || res.data.length === 0) {
        this.loadingPage = false;
        return;
      }

      res.data.forEach(elem => {
        const comp = {
          name: this.parseName(elem),
          sku: elem.sku,
          returned: elem.stock_returned ?? '-',
          receiving: elem.stock_receiving ?? '-',
          reserve_storage: elem.stock_reserved ?? '-',
          discharged: elem.stock_discharged ?? '-',
          stock: this.calculateTotalStock(elem) ?? 0,
          updated: moment(elem.updated_at).format('YYYY-MM-DD HH:mm'),
        };
        this.components.push(comp);
      });
      this.loadingPage = false;
    },
    async getSkus() {
      this.loadingPage = true;

      this.components = [];

      const page = this.currentPage - 1;

      const params = {
        offset: page * this.itemsPerPage,
        limit: this.itemsPerPage,
        product_type: this.$props.productType,
        sort_order: 'asc',
        sort_by: 'name',
      };

      try {
        const res = await this.$store.dispatch('OrderManagement/getSkus', params);
        if (res.skus == null || res.skus.length === 0) {
          return;
        }

        res.skus.forEach(elem => {
          const comp = {
            name: elem.description,
            sku: elem.name,
            stock: elem.stock || 0,
            updated: moment(elem.updated_at).format('YYYY-MM-DD HH:mm'),
          };
          this.components.push(comp);
        });

        this.total = res.total;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingPage = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.getSkus();
    },
    parseName(elem) {
      if (elem.type !== 'semi_kit') {
        return elem.name;
      }
      return elem.name.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, key => key.toUpperCase());
    },
    showPagination() {
      return this.total > 0;
    },
    isStockColumnVisible(columnName) {
      if (this.productType === ProductTypeSemiKit) {
        return SemiKitStockColumns.includes(columnName);
      }

      if (this.productType === ProductTypeMotionTracker) {
        return MotionTrackerStockColumns.includes(columnName);
      }

      if (this.productType === ProductTypeTablet) {
        return TabletStockColumns.includes(columnName);
      }

      if (this.productType === ProductTypeShippingMaterial) {
        return ShippingMaterialsStockColumns.includes(columnName);
      }

      return false;
    },
    calculateTotalStock(elem) {
      let totalStock = 0;

      if (this.isStockColumnVisible(StockLocationReturned)) {
        totalStock += elem.stock_returned ?? 0;
      }

      if (this.isStockColumnVisible(StockLocationReserved)) {
        totalStock += elem.stock_reserved ?? 0;
      }

      if (this.isStockColumnVisible(StockLocationDischarged)) {
        totalStock += elem.stock_discharged ?? 0;
      }

      if (this.isStockColumnVisible(StockLocationReceiving)) {
        totalStock += elem.stock_receiving ?? 0;
      }

      return totalStock;
    },
  },
};
</script>

<style scoped>

</style>
